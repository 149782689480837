<template>
    <div class="home">
        <HeaderComponent />
        <div class="margin-top-60">
        </div> 
        <FooterComponent />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'

export default {
  name: 'IndexPage',
  components: {
    HeaderComponent,
    FooterComponent
  },
  watch: {
      '$route.query': {
          immediate: true,
          handler() {
            document.title = 'Контакты'
          },
      },
  },
}
</script>

  