<template>
    <div class="product-inner">
        <div class="thumb has-second-image">
            <router-link :to="{ name: 'good', params: { good_id:id} }">
                <img v-if="mainImage" :src="mainImage" :alt="name">
                <img v-else :src="PreviewImage" :alt="name">
            </router-link> 
            <div class="group-button">
                <button v-if="itemInWishlist(id)" @click="addDelWishlistItem" class="wishlist in-wishlist"></button>
                <button v-else @click="addDelWishlistItem" class="wishlist"></button>
                <!-- <button @click="addToCart" :class="{ 'as-disabled' : outOfStock }" class="button add_to_cart_button"></button> -->
                <button @click="addToCart" class="button add_to_cart_button"></button>
            </div>
        </div>
        <div class="info">
            <h3 class="product-name short">
                <router-link 
                    data-toggle="tooltip" data-placement="top" :title="name"
                    :to="{ name: 'good', params: { good_id:id} }"
                >
                    {{ name }}
                </router-link>
            </h3>
            <span v-if="userToken" class="price">{{ price }}</span>
        </div>
    </div>
</template>

<script>

import PreviewImage from "@/assets/images/products/preview.png"
import {backendPath} from "@/main.js"

export default {
    name: 'GoodComponent',
    props: ['goodInfo'],
    data () {
        return {
            PreviewImage,
            id: this.goodInfo.id,
            name: this.goodInfo.full_name,
            art: this.goodInfo.art,
            price: this.goodInfo.price,
            balance: Math.floor(this.goodInfo.balance),
            loading: false,
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        mainImage () {
            let path = ''
            if (this.goodInfo.images.length > 0) {
                path = backendPath + this.goodInfo.images[0].image.url
            }
            return path
        },
        qtyInCart () {
            let cartItemById = this.$store.getters.cartItemById(this.id)
            let qtyInCart = 0
            if (cartItemById) {
                qtyInCart = cartItemById.quantity
            }
            return qtyInCart
        },
    },
    methods: {
        itemInWishlist (id) {
            if (this.userToken !='') {
                return this.$store.getters.wishlistItemById(id)
            }
            return null
        },
        addDelWishlistItem () {
            if (this.userToken != '') {
                if (this.itemInWishlist(this.goodInfo.id)) {
                    this.$store.dispatch('addDelWishlistItem', {good_id: this.goodInfo.id, authToken: this.userToken, action: 'del'})
                } else {
                    this.$store.dispatch('addDelWishlistItem', {good_id: this.goodInfo.id, authToken: this.userToken, action: 'add'})
                }
            }
        },
        addToCart () {
            if (this.userToken != '') {
                this.loading = true
                setTimeout(() => {
                    this.$store.dispatch('addDelCartItem', 
                    {
                        good_id: this.goodInfo.id,
                        authToken: this.userToken,
                        quantity: 1,
                        action: 'add'
                    })
                    this.loading = false
                }, 50)
            }
        },
    }
}
</script>