<template>
    <div class="col-xs-12 col-sm-12 col-md-3 sidebar">
        <!-- <a class="filter-navigation">
            <i class="fa fa-exchange" aria-hidden="true"></i>
            Фильтры
        </a>
        <div id="filter-mobile-menu" class="full-height full-width filter-mobile-menu">
            <div class="filter-inner">
                <a class="close-menu"><span class="icon fa fa-times"></span></a>
                <MobileFilterComponent />
            </div>
        </div> -->
        <FilterComponent />
    </div>
</template>

<script>
import FilterComponent from '@/components/Catalog/Sidebar/FilterComponent.vue'
// import MobileFilterComponent from '@/components/Catalog/Sidebar/MobileFilterComponent.vue'

export default {
    name: "SideBarComponent",
    components: {
        FilterComponent,
        // MobileFilterComponent,
    }
}
</script>