<template>
    <div class="home">
        <PreloaderComponent v-if="cart_loading || loading"/>
        <HeaderComponent />
        <div class="main-container no-sidebar padding-bottom-30">
            <div class="container">
              <DefaultBreadCrumbs pageName="Оформление заказа"/>
                <div class="checkout">
					<div class="row">
						<div v-if="!orderCreated" class="col">
							<div class="block-form">
                                <div class="as-block-form">
                                    <h3 class="form-heading">Детали заказа</h3>
                                    <p>
                                        <input 
                                            type="text" 
                                            placeholder="Контрагент" 
                                            readonly
                                            :value="customer"
                                        >
                                    </p>
                                    <p>
                                        <input 
                                            type="text" 
                                            placeholder="Договор" 
                                            readonly
                                            :value="contract_info"
                                        >
                                    </p>
                                    <p>
                                        <label for="orderComment"></label>
                                        <input 
                                            id="orderComment"
                                            type="text" 
                                            placeholder="Комментарий к заказу" 
                                            v-model="orderComment"
                                        >
                                    </p>
                                </div>
                                <p v-if="addresses">
                                    <VueSelect
                                    :isClearable="false"
                                    v-model="address"
                                    :options="addresses"
                                   
                                    />
                                </p>
							</div>
                            
							<div class="block-form order-review" align="right">
								<h3 class="form-heading">Ваш заказ</h3>
								<table class="shop_table">
                                    <thead>
                                        <tr>
                                            <th class="product-name">Товар</th>
                                            <th class="product-name">Остаток</th>
                                            <th class="product-name">Цена</th>
                                            <th class="product-total">Кол-во</th>
                                            <th class="product-total" style="text-align: right;">Сумма</th>
                                        </tr>
                                    </thead>
                                    <tbody id="order_table">
                                        <tr v-for="item in cart" v-bind:key="item.id">
                                            <CheckoutItemComponentVue 
                                            :goodInfo="item.good"
                                            :quantity="Number(item.quantity)"
                                            >
                                            </CheckoutItemComponentVue>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="order-total">
                                            <th></th>
                                            <th></th>
                                            <th>Итого:</th>
                                            <td></td>
                                            <td style="text-align: right;">
                                                <strong>
                                                    <span class="amount">{{ cartAmount }}</span>
                                                </strong>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
								<button @click="placeAnOrder" class="button primary">Разместить заказ</button>
							</div>
						</div>
                        <div v-else class="col">
                            <div class="col-sm-12">
                                <div class="text-center">
                                    <h2 class="title">Заказ успешно отправлен!</h2>
                                </div>
                                <div class="text-center padding-top-30">
                                    <router-link to="/" class="button primary">На главную</router-link>
                                </div>
                            </div>
                        </div>
					</div>
                </div>
            </div>
        </div>
        <FooterComponent />
        <ScrollOnTop />
        
    </div>
</template>

<script>
import VueSelect from "vue3-select-component"

import { useCookies } from "vue3-cookies"
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import ScrollOnTop from '@/components/ScrollOnTOP.vue'
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'
import PreloaderComponent from '@/components/PreloaderComponent.vue'
import CheckoutItemComponentVue from '@/components/User/Checkout/CheckoutItemComponent.vue'
// import "vue3-select-component/dist/style.css"


export default {
    name: "CheckoutView",
    components: {
        HeaderComponent,
        FooterComponent,
        ScrollOnTop,
        DefaultBreadCrumbs,
        PreloaderComponent,
        CheckoutItemComponentVue,
        VueSelect,
    },
    setup() {
        const { cookies } = useCookies()
        return { cookies }
    },
    data () {
        return {
            loading: false,
            orderCreated: false,
            orderComment: "",
            address: "",
        }
    },
    computed: {
        user () {
            return this.$store.getters.user
        },
        userToken () {
            return this.$store.getters.user_token
        },
        cart () {
            return this.$store.getters.cart
        },
        cartQty () {
            return this.$store.getters.cartQty
        },
        cart_loading () {
            return this.$store.getters.cart_loading
        },
        cartAmount () {
            return this.$store.getters.cartAmount.toFixed(2)
        },
        contracts_loading () {
            return this.$store.getters.contracts_loading
        },
        contract () {
            return this.$store.getters.contracts[0]
        },
        customer () {
            if (this.contract) {
                return `${this.contract.customer.name}, ИНН: ${this.contract.customer.inn}`
            }
            return ''
        },
        client () {
            if (this.contract) {
                return this.contract.client
            }
            return ''
        },
        addresses () {
            return this.getClientAddresses()
        },
        contract_info () {
            if (this.contract) {
                return `${this.contract.name} ${this.contract.number} от ${this.contract.date}`
            }
            return ''
        }
    },
    methods: {
        getClientAddresses () {
            let addresses = []
            if (this.client) {
                this.client.addresses.forEach((item) => {
                    addresses.push({
                        label: item.address,
                        value: item.id
                    })
                })
                this.address = this.client.addresses[0].id
            }
            return addresses
        },
        placeAnOrder () {
            if (this.contract && this.customer && this.cartQty > 0) {
                this.loading = true
                setTimeout(() => {
                    this.$store.dispatch('placeAnOrder', 
                    {   
                        contract_id: this.contract.id,
                        cart: this.cart,
                        authToken: this.userToken,
                        orderComment: this.orderComment,
                        address: this.address
                    }).then(() => {
                        this.$store.dispatch('deleteAllCartItems', this.userToken)
                        this.orderCreated = true
                    }).finally(() => {
                        this.loading = false
                    })
                }, 200)
            }
        }
    },
    watch: {
    '$route': {
        immediate: true,
        handler() {
            document.title = 'Оформление заказа'
        }
    },
  } 
}
</script>

<style scoped>
:deep(.vue-select) {
    --vs-padding: 9px 25px;
    --vs-border: 1px solid #e5e5e5;
    --vs-border-radius: 3px;
    --vs-font-size: 14px;
    --vs-font-weight: inherit;
    --vs-font-family: inherit;
    --vs-border-radius: 0;
    --vs-text-color: #666;
    --vs-option-font-size: 14px;
    --vs-option-text-color: #666;
    --vs-option-hover-color: #e4e4e4;
    --vs-option-bg: #cccccc;
    --vs-option-selected-color: #e4e4e4;
    --vs-option-focused-color: #cccccc;
    --vs-icon-color: #666;
    --vs-menu-border: 1px solid #e5e5e5;
}
</style>
