<template>
    <div class="home">
        <HeaderComponent />
        <div class="main-container no-sidebar padding-bottom-30">
            <div class="container">
                <DefaultBreadCrumbs pageName="Личный кабинет"/>
                <div class="row">
                    <div class="col-sm-12 main-content">
                        <div class="wishlist-manager">
                            <div class="row">
                                <div class="col-sm-12">
                                    <UserAccountComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterComponent />
        <ScrollOnTop />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import ScrollOnTop from '@/components/ScrollOnTOP.vue'
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'
import UserAccountComponent from '@/components/User/UserAccountComponent.vue'

export default {
  name: 'UserAccountView',
  components: {
    HeaderComponent,
    FooterComponent,
    ScrollOnTop,
    DefaultBreadCrumbs,
    UserAccountComponent,
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
          document.title = 'Личный кабинет'
      },
    },
  },
}
</script>