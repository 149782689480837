<template>
    <PreloaderComponent v-if="feedBackFormLoading" />
    <Modal 
        :visible="feedbackFormIsVisible"
        :title="'Свяжитесь со мной'"
        :animation=true
        :closable=false
        :okButton="{
            text: 'Отправить',
            onclick: () => {
                sendFeedbackRequest()
            },
        }"
        :cancelButton="{
            text: 'Отмена',
            onclick: () => {
                closeFeedBackForm()
            },
        }"
    >
    <div>
        <div class="row">
            <div class="col-sm-12">
                <p>
                    <input 
                        style="width: 100%;" 
                        type="text" 
                        placeholder="Имя"
                        v-model="name"
                        @input="errors.name = false"
                    >
                    <span v-if="errors.name" class="out-of-stock">Обязательное поле</span>
                </p>
                <p>
                    <input
                        v-model="phoneNumber"
                        @keypress="isNumber($event)"
                        @paste="isNumber($event)"
                        maxlength="12"
                        style="width: 100%;"
                        type="text" 
                        placeholder="Телефон"
                        @input="errors.phoneNumber = false"
                    >
                    <span v-if="errors.phoneNumber" class="out-of-stock">Обязательное поле</span>
                </p>
                <p v-if="!userToken">
                    <SmartCaptcha :on-success="captchaSuccess" style="height: 100px" :sitekey="yandexCaptcha" />
                </p>
            </div>
        </div>
    </div>
    </Modal>
</template>

<script>
import { Modal } from 'usemodal-vue3'
import { yandexCaptcha } from "@/main.js" 
import PreloaderComponent from '@/components/PreloaderComponent.vue'
export default {
    name: 'FeedbackComponent',
    components: {
        Modal,
        PreloaderComponent
    },
    data () {
        return {
            name: "",
            phoneNumber: "",
            captchaToken: false,
            errors: {},
            yandexCaptcha
        }
    },
    computed: {
        feedbackFormIsVisible () {
            return this.$store.getters.feed_back_form_is_visible
        },
        userToken () {
            return this.$store.getters.user_token
        },
        feedbackResponseAnswer () {
            return this.$store.getters.feedback_response_answer
        },
        feedBackFormLoading () {
            return this.$store.getters.feed_back_form_loading
        }
    },
    methods: {
        captchaSuccess () {
            this.captchaToken = true
        },
        isNumber (evt) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+'];
            const keyPressed = evt.key;
            
            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
            }
        },
        closeFeedBackForm () {
            this.errors = {}
            this.$store.dispatch('setFeedbackFromIsVisible', false)
        },
        checkFormValidation () {
            let formValidation = true
            if (!this.name) {
                formValidation = false
                this.errors.name = true
            }
            if (!this.phoneNumber) {
                formValidation = false
                this.errors.phoneNumber = true
            }
            if (!this.userToken && !this.captchaToken) {
                formValidation = false
            }
            return formValidation
        },
        sendFeedbackRequest () {
            if (this.checkFormValidation()) {
                let data = {
                    name: this.name,
                    phoneNumber: this.phoneNumber
                }
                if (this.$route.name === 'good' && this.userToken != "") {
                    data.link = window.location.href
                }
                if (this.userToken != "") {
                    data.authToken = this.userToken
                }
                this.$store.dispatch('sendFeedbackRequest', data).then(()=>{
                    if (this.feedbackResponseAnswer) {
                        this.$notify({
                            title: `${this.feedbackResponseAnswer}`,
                        })
                    }
                }).finally(() => {
                    this.name = ""
                    this.phoneNumber = ""
                })
            }
        }
    }
}
</script>