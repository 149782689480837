<template>
    <router-link class="cart-link" :to="{ name: 'cart'}">
        <span class="menu-icon  icon flaticon-shopping-cart6"></span>
        <span class="count">{{ cartQty }}</span>
        <!-- <span class="text">шт</span> -->
        <span class="kak">-</span>
        <span class="subtotal">{{ cartAmount }}</span>
    </router-link>
</template>

<script>
import { useCookies } from "vue3-cookies"
export default {
  name: 'CartHeader',
  setup() {
      const { cookies } = useCookies()
      return { cookies }
  },
  computed: {
    userToken () {
      return this.$store.getters.user_token
    },
    cartQty () {
      return this.$store.getters.cartQty
    },
    cartAmount () {
      return this.$store.getters.cartAmount.toFixed(2)
    },
  },
  mounted () {
    this.loadCart()
  },
  methods: {
      loadCart () {
          let authToken = this.cookies.get("sidrograd_token") 
          if (authToken) {
              this.$store.dispatch('loadCart', authToken)
          } else if (this.userToken) {
              this.$store.dispatch('loadCart', this.userToken)
          }
      }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        if (this.cookies.get("sidrograd_token")) {
          this.loadCart()
        }
      },
    }
  }
}
</script>
