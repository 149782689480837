<template>
    <div class="home">
        <HeaderComponent />
        <PageNotFoundComponent />
        <FooterComponent />
        <ScrollOnTop />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import ScrollOnTop from '@/components/ScrollOnTOP.vue'
import PageNotFoundComponent from '@/components/PageNotFoundComponent.vue'

export default {
    name: "PageNotFound",
    components: {
        HeaderComponent,
        FooterComponent,
        ScrollOnTop,
        PageNotFoundComponent
    },
}
</script>