<template>
    <Modal 
        :visible="ageVerificationFormVisibility"
        :title="''"
        :animation=true
        :closable=false
        :okButton="{
            text: 'Мне есть 18',
            onclick: () => {
                verificationConfirmed()
            },
        }"
        :cancelButton="{
            text: 'Мне нет 18',
            onclick: () => {
            },
        }"
    >
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <section class="offer">
                        <div class="white-popup">
                            <div class="popup-title">
                                <p
                                    style="text-align:center; font-size:24px;line-height: 1.3;"
                                    class="notice"
                                >Сайт содержит информацию о продуктах, предназначенных лицам старше 18 лет</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { Modal } from 'usemodal-vue3'
import { projectID } from '@/main.js'
export default {
    name: "AgeVerification",
    data () {
        return {
            ageVerificationFormVisibility: true
        }
    },
    setup() {
        const { cookies } = useCookies()
        return { cookies }
    },
    components: {
        Modal
    },
    mounted () {
        this.ageVerificationCheck()
    },
    methods: {
        verificationConfirmed () {
            console.log("confirmed")
            this.cookies.set(`${projectID}-age-verification`, true, "14d")
            this.ageVerificationFormVisibility = false
        },
        ageVerificationCheck () {
            let ageVerification = this.cookies.get(`${projectID}-age-verification`)
            if (ageVerification) {
                this.ageVerificationFormVisibility = false
            } else {
                this.ageVerificationFormVisibility = true
            }   
        }
    }
}
</script>

<style scoped>

</style>