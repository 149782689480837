<template>
  <HeaderComponent />
  <CategoriesWidget />
  <CarouselComponent />
  <FooterComponent />
  <ScrollOnTop />
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import ScrollOnTop from '@/components/ScrollOnTOP.vue'
import CategoriesWidget from '@/components/Widgets/CategoriesWidget.vue'
import CarouselComponent from '@/components/Widgets/CarouselComponent.vue'

export default {
  name: 'IndexPage',
  data () {
    return {
    }
  },
  components: {
    HeaderComponent,
    FooterComponent,
    ScrollOnTop,
    CategoriesWidget,
    CarouselComponent,
  },
  watch: {
      '$route.query': {
          immediate: true,
          handler() {
            document.title = 'Сидроград'
          },
      },
  },
}
</script>
