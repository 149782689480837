import Axios from 'axios'
import {backendPath} from "@/main.js"

export default {
    state: {
        goods: [],
        goods_qty: 0,
        page_qty: 0,
        goods_on_page_qty: 0,
		goods_list_loading: false,
        current_page: 1,
        availablePagesBeforeCurrent: [],
        availablePagesAfterCurrent: [],
        actual_filters_by_category: [],
        actual_filters_by_trade_mark: [],
        actual_filters_by_gassing: [],
        actual_filters_by_pasteurization: [],
        actual_filters_by_filtering: [],
        actual_filters_by_style: [],
        actual_filters_by_fermentation: [],
        actual_filters_by_manufacturer: [],
        actual_filters_by_strength: [],
        actual_filters_by_volume: [],
        actual_filters_by_country: [],
    },
    getters: {
        goods_list: state => {
            return state.goods;
        },
        goods_list_loading: state => {
            return state.goods_list_loading;
        },
        goods_qty: state => {
            return state.goods_qty;
        },
        page_qty: state => {
            return state.page_qty;
        },
        categoryName: state => {
            return state.category.name;
        },
        goods_on_page_qty: state => {
            return state.goods_on_page_qty;
        },
        current_page: state => {
            return state.current_page;
        },
        availablePagesBeforeCurrent: state => {
            return state.availablePagesBeforeCurrent;
        },
        availablePagesAfterCurrent: state => {
            return state.availablePagesAfterCurrent;
        },
        actualFilterByCategoryList: state => {
            return state.actual_filters_by_category
        },
        actualFilterByTradeMarkList: state => {
            return state.actual_filters_by_trade_mark
        },
        actualFilterByGassingList: state => {
            return state.actual_filters_by_gassing
        },
        actualFilterByFilteringList: state => {
            return state.actual_filters_by_filtering
        },
        actualFilterByStyleList: state => {
            return state.actual_filters_by_style
        },
        actualFilterByFermentationList: state => {
            return state.actual_filters_by_fermentation
        },
        actualFilterByManufacturerList: state => {
            return state.actual_filters_by_manufacturer
        },
        actualFilterByStrengthList: state => {
            return state.actual_filters_by_strength
        },
        actualFilterByVolumeList: state => {
            return state.actual_filters_by_volume
        },
        actualFilterByCountryList: state => {
            return state.actual_filters_by_country
        },
    },
    mutations: {
        loadGoodsList (state, payload) {
            state.goods = payload
        },
        goodsLoadingSwitch (state, payload) {
            state.goods_list_loading = payload
        },
        setGoodsQty (state, payload) {
            state.goods_qty = payload
        },
        setPageQty (state, payload) {
            state.page_qty = payload
        },
        setGoodsOnPageQty (state, payload) {
            state.goods_on_page_qty = payload
        },
        setCurrentPage (state, payload) {
            state.current_page = payload
        },
        setAvailablePagesBeforeCurrent (state, payload) {
            state.availablePagesBeforeCurrent = payload
        },
        setAvailablePagesAfterCurrent (state, payload) {
            state.availablePagesAfterCurrent = payload
        },
        clearActualFilterByCategory (state) {
            state.actual_filters_by_category = []
        },
        clearActualFilterByTradeMark (state) {
            state.actual_filters_by_trade_mark = []
        },
        clearActualFilterByGassing (state) {
            state.actual_filters_by_gassing = []
        },
        clearActualFilterByPasteurization (state) {
            state.actual_filters_by_pasteurization = []
        },
        clearActualFilterByFiltering (state) {
            state.actual_filters_by_filtering = []
        },
        clearActualFilterByStyle (state) {
            state.actual_filters_by_style = []
        },
        clearActualFilterByFermentation (state) {
            state.actual_filters_by_fermentation = []
        },
        clearActualFilterByManufacturer (state) {
            state.actual_filters_by_manufacturer = []
        },
        clearActualFilterByStrength (state) {
            state.actual_filters_by_strength = []
        },
        clearActualFilterByVolume (state) {
            state.actual_filters_by_volume = []
        },
        clearActualFilterByCountry (state) {
            state.actual_filters_by_country = []
        },
        addDelActualFilterByCategory (state, payload) {
            if (state.actual_filters_by_category.indexOf(payload) == -1) {
                state.actual_filters_by_category.push(payload)
            } else {
                let index = state.actual_filters_by_category.indexOf(payload)
                state.actual_filters_by_category.splice(index, 1)
            }
        },
        addDelActualFilterByTradeMark (state, payload) {
            if (state.actual_filters_by_trade_mark.indexOf(payload) == -1) {
                state.actual_filters_by_trade_mark.push(payload)
            } else {
                let index = state.actual_filters_by_trade_mark.indexOf(payload)
                state.actual_filters_by_trade_mark.splice(index, 1)
            }
        },
        addDelActualFilterByGassing (state, payload) {
            if (state.actual_filters_by_gassing.indexOf(payload) == -1) {
                state.actual_filters_by_gassing.push(payload)
            } else {
                let index = state.actual_filters_by_gassing.indexOf(payload)
                state.actual_filters_by_gassing.splice(index, 1)
            }
        },
        addDelActualFilterByPasteurization (state, payload) {
            if (state.actual_filters_by_pasteurization.indexOf(payload) == -1) {
                state.actual_filters_by_pasteurization.push(payload)
            } else {
                let index = state.actual_filters_by_pasteurization.indexOf(payload)
                state.actual_filters_by_pasteurization.splice(index, 1)
            }
        },
        addDelActualFilterByFiltering (state, payload) {
            if (state.actual_filters_by_filtering.indexOf(payload) == -1) {
                state.actual_filters_by_filtering.push(payload)
            } else {
                let index = state.actual_filters_by_filtering.indexOf(payload)
                state.actual_filters_by_filtering.splice(index, 1)
            }
        },
        addDelActualFilterByStyle (state, payload) {
            if (state.actual_filters_by_style.indexOf(payload) == -1) {
                state.actual_filters_by_style.push(payload)
            } else {
                let index = state.actual_filters_by_style.indexOf(payload)
                state.actual_filters_by_style.splice(index, 1)
            }
        },
        addDelActualFilterByFermentation (state, payload) {
            if (state.actual_filters_by_fermentation.indexOf(payload) == -1) {
                state.actual_filters_by_fermentation.push(payload)
            } else {
                let index = state.actual_filters_by_fermentation.indexOf(payload)
                state.actual_filters_by_fermentation.splice(index, 1)
            }
        },
        addDelActualFilterByManufacturer (state, payload) {
            if (state.actual_filters_by_manufacturer.indexOf(payload) == -1) {
                state.actual_filters_by_manufacturer.push(payload)
            } else {
                let index = state.actual_filters_by_manufacturer.indexOf(payload)
                state.actual_filters_by_manufacturer.splice(index, 1)
            }
        },
        addDelActualFilterByStrength (state, payload) {
            if (state.actual_filters_by_strength.indexOf(payload) == -1) {
                state.actual_filters_by_strength.push(payload)
            } else {
                let index = state.actual_filters_by_strength.indexOf(payload)
                state.actual_filters_by_strength.splice(index, 1)
            }
        },
        addDelActualFilterByVolume (state, payload) {
            if (state.actual_filters_by_volume.indexOf(payload) == -1) {
                state.actual_filters_by_volume.push(payload)
            } else {
                let index = state.actual_filters_by_volume.indexOf(payload)
                state.actual_filters_by_volume.splice(index, 1)
            }
        },
        addDelActualFilterByCountry (state, payload) {
            if (state.actual_filters_by_country.indexOf(payload) == -1) {
                state.actual_filters_by_country.push(payload)
            } else {
                let index = state.actual_filters_by_country.indexOf(payload)
                state.actual_filters_by_country.splice(index, 1)
            }
        },
    },
    actions: {
        setAvailablePagesBeforeCurrent ({commit}, payload) {
            commit('setAvailablePagesBeforeCurrent', payload)
        },
        setAvailablePagesAfterCurrent ({commit}, payload) {
            commit('setAvailablePagesAfterCurrent', payload)
        },
        setCurrentPage ({commit}, payload) {
            commit('setCurrentPage', payload)
        },
        getGoodsList ({commit}, params) {
            Axios.defaults.xsrfCookieName = 'csrftoken'
            Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            
            let page = 1
            let goodsQtyOnPage = 45
            let q = ''
            let authToken = ''
            if (params.page) {
                page = params.page
            }
            if (params.goodsQtyOnPage) {
                goodsQtyOnPage = params.goodsQtyOnPage
            }
            if (params.q) {
                q = params.q
            }
            let url = `${backendPath}/api/v1/catalog/good`
            if (q) {
                url = url + `/?search=${q}&page=${page}&count=${goodsQtyOnPage}`
            } else {
                let tempUrl = "/?"
                if (params.category) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `category_id=${params.category}`
                }
                if (params.trade_mark) {
                    
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `trade_mark_id=${params.trade_mark}`
                }
                if (params.gassing) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `gassing_id=${params.gassing}`
                }
                if (params.pasteurization) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `pasteurization_id=${params.pasteurization}`
                }
                if (params.filtering) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `filtering_id=${params.filtering}`
                }
                if (params.style) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `style_id=${params.style}`
                }
                if (params.fermentation) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `type_of_fermentation_id=${params.fermentation}`
                }
                if (params.manufacturer) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `manufacturer_id=${params.manufacturer}`
                }
                if (params.strength) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `strength_id=${params.strength}`
                }
                if (params.volume) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `volume_id=${params.volume}`
                }
                if (params.country) {
                    if (tempUrl != "/?") {
                        tempUrl += '&' 
                    }
                    tempUrl += `country_id=${params.country}`
                }
                if (tempUrl != "/?") {
                    tempUrl += '&' 
                }
                tempUrl += `page=${page}&count=${goodsQtyOnPage}`

                url = url + tempUrl
            }
            if (params.authToken) {
                authToken = params.authToken
            }
            let headers = {}
            if (authToken) {
                headers.Authorization = `Token ${authToken}`
            }
            commit('setGoodsOnPageQty', goodsQtyOnPage)
            commit('goodsLoadingSwitch', true)
            commit('clearActualFilterByCategory')
            commit('clearActualFilterByTradeMark')
            commit('clearActualFilterByGassing')
            commit('clearActualFilterByPasteurization')
            commit('clearActualFilterByFiltering')
            commit('clearActualFilterByStyle')
            commit('clearActualFilterByFermentation')
            commit('clearActualFilterByManufacturer')
            commit('clearActualFilterByStrength')
            commit('clearActualFilterByVolume')
            commit('clearActualFilterByCountry')
            Axios({
                method: 'get',
                headers: headers,
                url: url,
            }).then((response) => {
                let page_qty = 1
                if (response.data.count % goodsQtyOnPage > 0) {
                    page_qty = Math.floor(response.data.count/goodsQtyOnPage) + 1
                } else {
                    page_qty = Math.floor(response.data.count/goodsQtyOnPage)
                }
                commit('setPageQty', page_qty)
                commit('setCurrentPage', page)
                commit('setGoodsQty', response.data.count) 
                commit('loadGoodsList', response.data.data)
                let availablePagesBeforeCurrent = []
                if (page <= 2) {
                    for (let i = 1; i < page; i++) {
                        availablePagesBeforeCurrent.push(i)
                    }
                } else {
                    for (let i = (page-2); i < page; i++) {
                        availablePagesBeforeCurrent.push(i)
                    }
                }
                availablePagesBeforeCurrent = availablePagesBeforeCurrent.sort(function(a, b) {
                    return a - b;
                });
                commit('setAvailablePagesBeforeCurrent', availablePagesBeforeCurrent)

                let availablePagesAfterCurrent = []
                if ((page_qty - page) <= 2) {
                    for (let i = (page+1); i <= page_qty; i++) {
                        availablePagesAfterCurrent.push(i)
                    }
                } else {
                    for (let i = (page+1); i < (page+3); i++) {
                        availablePagesAfterCurrent.push(i)
                    }
                }
                availablePagesAfterCurrent = availablePagesAfterCurrent.sort(function(a, b) {
                    return a - b;
                });
                commit('setAvailablePagesAfterCurrent', availablePagesAfterCurrent)
                if (response.data.params) {
                    if (response.data.params.category_id!='' && response.data.params.category_id!=undefined) {
                        let categoryList = response.data.params.category_id.split(',')
                        for (let i = 0; i<(categoryList.length); i++) {
                            commit('addDelActualFilterByCategory', categoryList[i])
                        }
                    }
                    if (response.data.params.trade_mark_id!='' && response.data.params.trade_mark_id!=undefined) {
                        let tradeMarkList = response.data.params.trade_mark_id.split(',')
                        for (let i = 0; i<(tradeMarkList.length); i++) {
                            commit('addDelActualFilterByTradeMark', tradeMarkList[i])
                        }
                    }
                    if (response.data.params.manufacturer_id!='' && response.data.params.manufacturer_id!=undefined) {
                        let manufacturerList = response.data.params.manufacturer_id.split(',')
                        for (let i = 0; i<(manufacturerList.length); i++) {
                            commit('addDelActualFilterByManufacturer', manufacturerList[i])
                        }
                    }
                    if (response.data.params.filtering_id!='' && response.data.params.filtering_id!=undefined) {
                        let filteringList = response.data.params.filtering_id.split(',')
                        for (let i = 0; i<(filteringList.length); i++) {
                            commit('addDelActualFilterByFiltering', filteringList[i])
                        }
                    }
                    if (response.data.params.gassing_id!='' && response.data.params.gassing_id!=undefined) {
                        let gassingList = response.data.params.gassing_id.split(',')
                        for (let i = 0; i<(gassingList.length); i++) {
                            commit('addDelActualFilterByGassing', gassingList[i])
                        }
                    }
                    if (response.data.params.pasteurization_id!='' && response.data.params.pasteurization_id!=undefined) {
                        let pasteurizationList = response.data.params.pasteurization_id.split(',')
                        for (let i = 0; i<(pasteurizationList.length); i++) {
                            commit('addDelActualFilterByPasteurization', pasteurizationList[i])
                        }
                    }
                    if (response.data.params.style_id!='' && response.data.params.style_id!=undefined) {
                        let styleList = response.data.params.style_id.split(',')
                        for (let i = 0; i<(styleList.length); i++) {
                            commit('addDelActualFilterByStyle', styleList[i])
                        }
                    }
                    if (response.data.params.type_of_fermentation_id!='' && response.data.params.type_of_fermentation_id!=undefined) {
                        let fermentationList = response.data.params.type_of_fermentation_id.split(',')
                        for (let i = 0; i<(fermentationList.length); i++) {
                            commit('addDelActualFilterByFermentation', fermentationList[i])
                        }
                    }
                    if (response.data.params.volume_id!='' && response.data.params.volume_id!=undefined) {
                        let volumeList = response.data.params.volume_id.split(',')
                        for (let i = 0; i<(volumeList.length); i++) {
                            commit('addDelActualFilterByVolume', volumeList[i])
                        }
                    }
                    if (response.data.params.strength_id!='' && response.data.params.strength_id!=undefined) {
                        let strengthList = response.data.params.strength_id.split(',')
                        for (let i = 0; i<(strengthList.length); i++) {
                            commit('addDelActualFilterByStrength', strengthList[i])
                        }
                    }
                    if (response.data.params.country_id!='' && response.data.params.country_id!=undefined) {
                        let countryList = response.data.params.country_id.split(',')
                        for (let i = 0; i<(countryList.length); i++) {
                            commit('addDelActualFilterByCountry', countryList[i])
                        }
                    }
                }
            }).catch(function(error){
                console.log(error);
            }).finally (() => {
                commit('goodsLoadingSwitch', false)
            })
        },
        addDelActualFilterByCategory ({commit}, payload) {
            commit('addDelActualFilterByCategory', payload)
        },
        addDelActualFilterByTradeMark ({commit}, payload) {
            commit('addDelActualFilterByTradeMark', payload)
        },
        addDelActualFilterByVolume ({commit}, payload) {
            commit('addDelActualFilterByVolume', payload)
        },
        addDelActualFilterByStrength ({commit}, payload) {
            commit('addDelActualFilterByStrength', payload)
        },
        addDelActualFilterByManufacturer ({commit}, payload) {
            commit('addDelActualFilterByManufacturer', payload)
        },
        addDelActualFilterByPasteurization ({commit}, payload) {
            commit('addDelActualFilterByPasteurization', payload)
        },
        addDelActualFilterByFiltering ({commit}, payload) {
            commit('addDelActualFilterByFiltering', payload)
        },
        addDelActualFilterByStyle ({commit}, payload) {
            commit('addDelActualFilterByStyle', payload)
        },
        addDelActualFilterByFermentation ({commit}, payload) {
            commit('addDelActualFilterByFermentation', payload)
        },
        addDelActualFilterByGassing ({commit}, payload) {
            commit('addDelActualFilterByGassing', payload)
        },
        addDelActualFilterByCountry ({commit}, payload) {
            commit('addDelActualFilterByCountry', payload)
        },
    }
}