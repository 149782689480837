<template>
    <div class="block-form">
        <h3 class="form-heading">Личный кабинет</h3>
        <div class="row">
            <div class="col-sm-6 padding-left-30">
                <button @click="userLogout" class="button">Выйти</button>
                <p></p>
                <p>
                    Добро пожаловать! Вы авторизовались как {{ user.username }} 
                </p>
                <p>
                    Ваш Email: {{ user.email }} 
                </p>
                <p>
                    <router-link :to="{ name: 'usersOrderList', query: { page: 1} }">История заказов</router-link>
                </p>
            </div>

            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <span>Имя пользователя</span>
                        <p><input readonly type="text" placeholder="Имя" :value="user.username"></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <span>Телефон</span>
                        <p><input readonly type="text" placeholder="Телефон" value="+7 (999) 000-0000"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useCookies } from "vue3-cookies"
export default {
    name: 'UserAccountComponent',
    setup() {
		const { cookies } = useCookies()
		return { cookies }
	},
    computed: {
        user () {
            return this.$store.getters.user
        }
    },
    created() {
        if (!this.cookies.get("sidrograd_token")) {
            this.$router.push({ name: 'userLogin'})
        }
    },
    methods: {
        userLogout () {
            this.$store.dispatch('setUserToken', '')
            this.$store.dispatch('clearUserInfo', {})
            this.$store.dispatch('clearWishlist', {})
            this.$router.push({ name: 'userLogin' })
        },
    },
    $route: {
        immediate: true,
        handler() {
            document.title = 'Личный кабинет'
        }
    },
}
</script>
