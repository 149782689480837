import Axios from 'axios'
import {backendPath} from "@/main.js"

export default {
    state: {
        filter_by_category_list: [],
        filter_by_trade_mark_list: [],
        filter_by_gassing_list: [],
        filter_by_filtering_list: [],
        filter_by_style_list: [],
        filter_by_fermentation_list: [],
        filter_by_manufacturer_list: [],
        filter_by_strength_list: [],
        filter_by_volume_list: [],
        filter_by_country_list: [],
        manufacturer_info: "",
		filter_list_loading: false,
    },
    getters: {
        filterByCategoryList: state => {
            return state.filter_by_category_list
        },
        filterByTradeMarkList: state => {
            return state.filter_by_trade_mark_list
        },
        filterByGassingList: state => {
            return state.filter_by_gassing_list
        },
        filterByFilteringList: state => {
            return state.filter_by_filtering_list
        },
        filterByStyleList: state => {
            return state.filter_by_style_list
        },
        filterByFermentationList: state => {
            return state.filter_by_fermentation_list
        },
        filterByManufacturerList: state => {
            return state.filter_by_manufacturer_list
        },
        filterByStrengthList: state => {
            return state.filter_by_strength_list
        },
        filterByVolumeList: state => {
            return state.filter_by_volume_list
        },
        filterByCountryList: state => {
            return state.filter_by_country_list
        },
        manufacturerInfo: state => {
            return state.manufacturer_info
        },
        filter_list_loading: state => {
            return state.filter_list_loading
        }
    },
    mutations: {
        loadFilterByCategoryList (state, payload) {
            state.filter_by_category_list = payload
        },
        loadFilterByTradeMarkList (state, payload) {
            state.filter_by_trade_mark_list = payload
        },
        loadFilterByGassingList (state, payload) {
            state.filter_by_gassing_list = payload
        },
        loadFilterByFilteringList (state, payload) {
            state.filter_by_filtering_list = payload
        },
        loadFilterByStyleList (state, payload) {
            state.filter_by_style_list = payload
        },
        loadFilterByFermentationList (state, payload) {
            state.filter_by_fermentation_list = payload
        },
        loadFilterByManufacturerList (state, payload) {
            state.filter_by_manufacturer_list = payload
        },
        loadFilterByStrengthList (state, payload) {
            state.filter_by_strength_list = payload
        },
        loadFilterByVolumeList (state, payload) {
            state.filter_by_volume_list = payload
        },
        loadFilterByCountryList (state, payload) {
            state.filter_by_country_list = payload
        },
        loadManufacturerInfo (state, payload) {
            state.manufacturer_info = payload
        },
        filterListLoadingSwitch (state, payload) {
            state.filter_list_loading = payload
        }
    },
    actions: {
        loadFilterList ({commit}, params) {
            Axios.defaults.xsrfCookieName = 'csrftoken'
            Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
            let url = `${backendPath}/api/v1/catalog/data`
            let query = "/?"
            let authToken = ''
            if (params.category) {
                if (query != "/?") {
                    query += '&' 
                }
                query += `category_id=${params.category}`
            }
            if (params.trade_mark) {
                
                if (query != "/?") {
                    query += '&' 
                }
                query += `trade_mark_id=${params.trade_mark}`
            }
            if (params.gassing) {
                if (query != "/?") {
                    query += '&' 
                }
                query += `gassing_id=${params.gassing}`
            }
            if (params.filtering) {
                if (query != "/?") {
                    query += '&' 
                }
                query += `filtering_id=${params.filtering}`
            }
            if (params.style) {
                if (query != "/?") {
                    query += '&' 
                }
                query += `style_id=${params.style}`
            }
            if (params.fermentation) {
                if (query != "/?") {
                    query += '&' 
                }
                query += `type_of_fermentation_id=${params.fermentation}`
            }
            if (params.manufacturer) {
                if (query != "/?") {
                    query += '&' 
                }
                query += `manufacturer_id=${params.manufacturer}`
            }
            if (params.strength) {
                if (query != "/?") {
                    query += '&' 
                }
                query += `strength_id=${params.strength}`
            }
            if (params.volume) {
                if (query != "/?") {
                    query += '&' 
                }
                query += `volume_id=${params.volume}`
            }
            if (params.country) {
                if (query != "/?") {
                    query += '&' 
                }
                query += `country_id=${params.country}`
            }
            url += query
            if (params.authToken) {
                authToken = params.authToken
            }
            let headers = {}
            if (authToken) {
                headers.Authorization = `Token ${authToken}`
            }
            commit('loadManufacturerInfo', "")
            commit('filterListLoadingSwitch', true)
            Axios({
                method: 'get',
                headers: headers,
                url: url,
            }).then((response) => {
                commit('loadFilterByCategoryList', response.data.data.category)
                commit('loadFilterByTradeMarkList', response.data.data.trade_mark)
                commit('loadFilterByGassingList', response.data.data.gassing)
                commit('loadFilterByFilteringList', response.data.data.filtering)
                commit('loadFilterByStyleList', response.data.data.style)
                commit('loadFilterByFermentationList', response.data.data.type_of_fermentation)
                commit('loadFilterByManufacturerList', response.data.data.manufacturer)
                if (response.data.params.manufacturer_id) {
                    let manufacturer_id_list = response.data.params.manufacturer_id.split(',')
                    if (manufacturer_id_list.length == 1) {
                        let manID = manufacturer_id_list[0]
                        let manufacturer = response.data.data.manufacturer.find(el => el.id === manID)
                        commit('loadManufacturerInfo', manufacturer)
                    }
                }
                commit('loadFilterByStrengthList', response.data.data.strength)
                commit('loadFilterByVolumeList', response.data.data.volume)
                commit('loadFilterByCountryList', response.data.data.country)
            }).catch((error) => {
                console.log(error)
            }). finally (() => {
                commit('filterListLoadingSwitch', false)
            })
        },
    }
}