<template>
    <router-link v-if="user" :to="{ name: 'userAccount' }"><span class="menu-icon flaticon-user5"></span>{{ user.username }}</router-link>
    <router-link v-else :to="{ name: 'userLogin' }"><span class="menu-icon flaticon-user5"></span>Войти</router-link>
    <router-link :to="{ name: 'wishlist' }"><span class="menu-icon flaticon-heart295"></span>Избранное</router-link>
</template>

<script>
export default {
  name: 'AccountMenu',
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    getUserToken () {
      return this.$store.dispatch('userAuthentication')
    }
  },
  watch: {
      '$route': {
          immediate: true,
          handler() {
              // this.getUserToken()
          },
      },
  }
}
</script>
