<template>
    <div class="home">
        <HeaderComponent />
        <div class="main-container no-sidebar padding-bottom-30">
            <div class="container">
                <DefaultBreadCrumbs pageName="История заказов"/>
                <div class="row">
                    <div class="col-sm-12 main-content">
                        <div class="wishlist-manager">
                            <div class="row">
                                <OrderListContentComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterComponent />
        <ScrollOnTop />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import ScrollOnTop from '@/components/ScrollOnTOP.vue'
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'
import OrderListContentComponent from '@/components/User/Order/OrderListContentComponent.vue'

export default {
    name: "OrdersListView",
    components: {
        HeaderComponent,
        FooterComponent,
        ScrollOnTop,
        DefaultBreadCrumbs,
        OrderListContentComponent,
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Личный кабинет'
            },
        },
    },
}
</script>