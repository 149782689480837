<template>
    <ul class="products product-list-grid desktop-columns-3 tablet-columns-3 mobile-columns-1">
        <li 
            class="product-item col-md-4 col-sm-4 col-xs-12"
            v-for="item in demoContentCount" v-bind:key="item.id"
            >
            <div class="product-inner">
                <div class="thumb has-second-image">
                    <img :src="PreviewImage" alt="">
                </div>
                <div class="info">
                </div>
            </div>
        </li>
    </ul>
</template>
<script>
import PreviewImage from "@/assets/images/products/1.jpg"

export default {
    name: "ContentPreview",
    data() {
        return {
            demoContentCount: 27,
            PreviewImage
        }
    }
}
</script>
