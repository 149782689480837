<template>
    <div class="main-container" style="padding-bottom: 10px;">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 main-content padding-0">
                    <div 
                        v-for="category in categories_list" :key="category.id"
                        class="post-item grid col-sm-3"
                    >
						<div class="post-thumb">
							<router-link :to="{ name: 'catalog', query: { category: category.id, page: 1 }}">
                                <img v-if="category.image" :src="getCategoryImagePath(category.id)" :alt="category.name">
                                <img v-else :src="defaultBG" :alt="category.name">
                            </router-link>
						</div>
						<!-- <div class="as-post-info">
							<h3 class="as-post-title">
                                <router-link :to="{ name: 'catalog', query: { category: category.id, page: 1 }}">
                                    {{ category.name }}
                                </router-link>
                            </h3>
						</div> -->
                        <div class="as-category-title">
                            <router-link :to="{ name: 'catalog', query: { category: category.id, page: 1 }}">
                                {{ category.name }}
                            </router-link>
                        </div>
					</div>
                </div>
            </div>
        </div>
    </div>                
</template>

<script>
import {backendPath} from "@/main.js" 
import defaultBG from "@/assets/images/backgrounds/categories_widget_background.jpg"
export default {
    name: "CategoriesWidget",
    data () {
		return {
            defaultBG
		}
	},
    computed: {
        categories_list () {
            return this.$store.getters.categories_list
        }
    },
    methods: {
        getCategoryImagePath (id) {
            let category = this.$store.getters.getCategoryById(id)
            let path = `${backendPath}${category.image}` 
            return path
        },
    }
}
</script>