<template>
    <div class="home">
        <HeaderComponent />
        <div class="main-container left-sidebar">
          <div class="container">
            <div class="row">
              <SideBarComponent />
              <div class="col-sm-12 col-md-9 main-content">
                <div class="shop-page-bar">
                  <CatalogBreadCrumbs />
                  <div class="page-bar-right">
                    <!-- <SortingComponent /> -->
                  </div>
                </div>
                <ContentComponent />
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
        <ScrollOnTop />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import CatalogBreadCrumbs from '@/components/Catalog/CatalogBreadCrumbs.vue'
import ContentComponent from '@/components/Catalog/ContentComponent.vue'
import ScrollOnTop from '@/components/ScrollOnTOP.vue'
import SideBarComponent from '@/components/Catalog/Sidebar/SideBarComponent.vue'
// import SortingComponent from '@/components/Catalog/SortingComponent.vue'

export default {
  name: 'IndexPage',
  components: {
    HeaderComponent,
    FooterComponent,
    CatalogBreadCrumbs,
    ContentComponent,
    ScrollOnTop,
    SideBarComponent,
    // SortingComponent
  },
  mounted () {
    window.scrollTo({
        top: 200,
        behavior: 'smooth'
    })
  },
  watch: {
      '$route.query': {
          immediate: true,
          handler() {
            document.title = 'Каталог'
          },
      },
  },
}
</script>
