<template>
	<div id="box-mobile-menu" class="full-height full-width box-mobile-menu">
		<div class="box-inner">
			<a class="close-menu"><span class="icon fa fa-times"></span></a>
		</div>
	</div>
	<div id="header-ontop" class="is-sticky"></div>
	<div id="header" class="header style2 style3">
		<div class="top-bar">
			<div class="container">
				<ul class="kt-nav top-bar-menu">
					<TopBarMenu />
				</ul>
				<ul class="kt-nav top-bar-menu right">
					<li class="menu-item-has-children">
						<AccountMenu />
					</li>
				</ul>
			</div>
		</div>
		<div class="main-header">
			<div class="container">
				<div class="logo">
					<router-link to="/"><img width="280" :src="logo" alt=""></router-link>
				</div>
				<div class="box-serach-wapper">
					<SearchComponent />
				</div>
				<div class="mini-cart">
					<CartHeader />
				</div>
				<div class="main-menu-wapper">
					<MenuComponent />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useCookies } from "vue3-cookies"
import logo from '@/assets/images/logos/logo.png'
import TopBarMenu from '@/components/Header/TopBarMenu.vue'
import AccountMenu from '@/components/Header/AccountMenu.vue'
import SearchComponent from '@/components/Header/SearchComponent.vue'
import CartHeader from '@/components/Header/CartHeader.vue'
import MenuComponent from '@/components/Header/MenuComponent.vue'

export default {
	name: 'HeaderComponent',
	setup() {
		const { cookies } = useCookies()
		return { cookies }
		},
	data () {
		return {
			logo
		}
	},
	components: {
		TopBarMenu,
		AccountMenu,
		SearchComponent,
		CartHeader,
		MenuComponent,
	},
	computed: {
      userToken () {
        return this.$store.getters.user_token
      },
      user () {
        return this.$store.getters.user
      },
    },
	mounted() {
		this.checkTheCookiesAuthToken()
		this.loadWishlist()
		this.loadContracts()
	},
	methods: {
		checkTheCookiesAuthToken () {
			let authToken = this.cookies.get("sidrograd_token")
			if (authToken) {
				this.$store.dispatch('setUserToken', authToken)
				this.$store.dispatch('loadUserInfo', authToken)
			}
		},
		loadWishlist () {
			let authToken = this.cookies.get("sidrograd_token") 
			if (authToken) {
				this.$store.dispatch('loadWishlist', authToken)
			} else if (this.userToken) {
				this.$store.dispatch('loadWishlist', this.userToken)
			}
		},
		loadContracts () {
			let authToken = this.cookies.get("sidrograd_token") 
			if (authToken) {
				this.$store.dispatch('loadContracts', authToken)
			} else if (this.userToken) {
				this.$store.dispatch('loadContracts', this.userToken)
			}
		}
    },
	watch: {
      userToken: {
        deep: true,
        handler() {
          if (this.userToken) {
            this.cookies.set('sidrograd_token', this.userToken)
            this.$store.dispatch('loadUserInfo', this.userToken)
          } else {
            this.cookies.remove('sidrograd_token')
            this.$store.dispatch('clearUserInfo', {})
          }
        }
      },
      '$route': {
        immediate: true,
        handler() {
			if (this.cookies.get("sidrograd_token")) {
				this.loadWishlist()
				this.loadContracts()
			}
        },
      }
    }
}
</script>
