<template>
    <div class="home">
        <HeaderComponent />
        <FullContentGood />
        <FooterComponent />
        <ScrollOnTop />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import ScrollOnTop from '@/components/ScrollOnTOP.vue'
import FullContentGood from '@/components/Catalog/Good/FullContentGood.vue'
// import SideBarComponent from '@/components/Catalog/Sidebar/SideBarComponent.vue'
// import SortingComponent from '@/components/Catalog/SortingComponent.vue'

export default {
  name: 'IndexPage',
  components: {
    HeaderComponent,
    FooterComponent,
    ScrollOnTop,
    FullContentGood,
    // SideBarComponent
    // SortingComponent
  },
  mounted () {
    window.scrollTo({
        top: 200,
        behavior: 'smooth'
    })
  }
}
</script>
