<template>
    <PreloaderComponent v-if="goodLoadingInfoStatus" /> 
    <PageNotFoundComponent v-if="!good" />
    <div v-if="good" class="main-container no-sidebar">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 main-content">
                    <GoodBreadCrumbs />
                    <div v-if="goodLoadingInfoStatus" class="single-product">
                        <PreviewComponent />
                    </div>
                    <div v-else class="single-product">
                        <div class="row">
                            <div class="col-sm-6 col-md-5 col-lg-5">
                                <div class="images kt-images">
                                    <div class="kt-main-image">
                                        <a v-if="mainImage" class="zoom">
                                            <img
                                                :src="mainImage"
                                                :alt="good.full_name"
                                                v-fullscreen-image="{
                                                    imageUrl: mainImage,
                                                    withDownload: false,
                                                    animation: 'blur'
                                                }"
                                            />
                                        </a>
                                        <a v-else class="zoom"><img :src="PreviewImage" alt=""></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-7 col-lg-7">
                                <div class="summary">
                                    <h1 
                                        class="product_title entry-title"
                                        style="color: #000;"
                                    >{{ good.full_name }}</h1>
                                    <p><label>Арт:</label> {{ good.art }}</p>
                                    <p v-if="userToken"><label>Цена:</label><span class="price"> {{ good.price }}</span></p>
                                    <p v-if="balance > 0 && userToken" class="stock in-stock">
                                        <label class="margin-right-10">Наличие:</label> 
                                        <i class="fa fa-check"></i> 
                                        в наличии {{ balance }}
                                        <span v-if="good.unit">{{ good.unit.name }}</span> 
                                    </p>
                                    <p v-if="balance <= 0 && userToken" class="stock out-of-stock">
                                        <label class="margin-right-10">Наличие:</label> 
                                        <i class="fa fa-times" style="color: orange;"></i>
                                        в наличии {{ balance }} 
                                        <span v-if="good.unit">{{ good.unit.name }}</span> 
                                    </p>
                                    <div v-if="good.description" class="short-descript">
                                        <p style="color: #000;"><strong>Описание:</strong> {{ good.description }}</p>
                                    </div>
                                    <div class="variation_form_section">
                                        <h2 class="product_title entry-title" style="font-size: 100%; font-weight: 600">Характеристики</h2>
                                        <div class="row">
                                            <div class="col-sm-6 col-md-6">
                                                <div v-if="good.manufacturer">
                                                    <label>Производитель:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { manufacturer: good.manufacturer.id } }">
                                                        {{ good.manufacturer.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.trade_mark">
                                                    <label>Торговая марка:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { trade_mark: good.trade_mark.id } }">
                                                        {{ good.trade_mark.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.country">
                                                    <label>Страна производства:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { country: good.country.id } }">
                                                        {{ good.country.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.gassing">
                                                    <label>Газация:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { gassing: good.gassing.id } }">
                                                        {{ good.gassing.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.filtering">
                                                    <label>Фильрация:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { filtering: good.filtering.id } }">
                                                        {{ good.filtering.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.pasteurization">
                                                    <label>Пастеризация:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { pasteurization: good.pasteurization.id } }">
                                                        {{ good.pasteurization.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.style">
                                                    <label>Стиль:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { style: good.style.id } }">
                                                        {{ good.style.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.type_of_fermentation">
                                                    <label>Ферментация:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { type_of_fermentation: good.type_of_fermentation.id } }">
                                                        {{ good.type_of_fermentation.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.volume">
                                                    <label>Объем:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { volume: good.volume.id } }">
                                                        {{ good.volume.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.strength">
                                                    <label>Крепость:</label>
                                                    <router-link 
                                                        :to="{ name: 'catalog', query: { strength: good.strength.id } }">
                                                        {{ good.strength.name }}
                                                    </router-link>
                                                </div>
                                                <div v-if="good.in_package">
                                                    <label>Упаковка:</label>
                                                    <a href="#">{{ good.in_package }} шт.</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="quantity"></div>
                                    <div class="wishlist-item">
                                        <div id="quantityform" class="row">
                                            <div class="col-xs-12 col-sm-12 col-md-5 as-quantity-block">
                                                <a @click="decreaseQty" class="remove qty-mgmt" href="#"><i class="fa fa-minus"></i></a>
                                                <input type="text" v-model="qty"  class="input-text qty text" size="1">
                                                <a @click="increaseQty" class="remove qty-mgmt" href="#"><i class="fa fa-plus"></i></a>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-7 as-cart-button-block">
                                                <button v-if="loading">
                                                    <div class="loader-button">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </button>
                                                <!-- <button v-else @click="addToCart" :class="{'as-disabled' : outOfStock }">В корзину</button> -->
                                                <button v-else @click="addToCart">В корзину</button>
                                                <button v-if="itemInWishlist(good.id)" @click="addDelWishlistItem" style="vertical-align:middle;" class="button wishlist in-wishlist"><span class="flaticon-heart295"></span></button>
                                                <button v-else @click="addDelWishlistItem" style="vertical-align:middle;" class="button wishlist"><span class="flaticon-heart295"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>            
                </div>
            </div>
        </div>
    </div>            
</template>

<script>
import PreviewImage from '@/assets/images/products/preview.png'
import GoodBreadCrumbs from '@/components/Catalog/Good/GoodBreadCrumbs.vue'
import PreviewComponent from '@/components/Catalog/Good/PreviewComponent.vue'
import PreloaderComponent from '@/components/PreloaderComponent.vue'
import PageNotFoundComponent from '@/components/PageNotFoundComponent.vue'
import {backendPath} from "@/main.js"

export default {
    name: "FullContentGood",
    components: {
        GoodBreadCrumbs,
        PreviewComponent,
        PreloaderComponent,
        PageNotFoundComponent
    },
    data () {
        return {
            PreviewImage,
            qty: 1,
            loading: false,
        }
    },
    computed: {
        good () {
            return this.$store.getters.good
        },
        amount () {
            return (this.qty * this.good.price).toFixed(2)
        },
        balance () {
            // return Math.floor(5)
            return Math.floor(this.good.balance)
        },
        volume () {
            return Number(this.good.volume).toFixed(1)
        },
        strength () {
            return Number(this.good.strength).toFixed(1)
        },
        mainImage () {
            let path = ''
            if (this.good.images.length > 0) {
                path = backendPath + this.good.images[0].image.url
            }
            return path
        },
        goodLoadingInfoStatus () {
            return this.$store.getters.good_info_loading
        },
        qtyInCart () {
            let cartItemById = this.$store.getters.cartItemById(this.good.id)
            let qtyInCart = 0
            if (cartItemById) {
                qtyInCart = cartItemById.quantity
            }
            return qtyInCart
        },
        userToken () {
            return this.$store.getters.user_token
        },
        outOfStock () {
            let outOfStock = false
            if (!(this.userToken != '' && this.balance > 0 && this.qtyInCart < this.balance)) {
                outOfStock = true
            }
            return outOfStock
        }
    },
    methods: {
        setPageTitle(payload) {
            document.title = payload
        },
        loadGoodInfo(id) {
            this.$store.dispatch('loadGoodInfo', id)
        },
        increaseQty(el) {
            el.preventDefault()
            this.qty ++
        },
        decreaseQty(el) {
            el.preventDefault()
            if (this.qty > 1) {
                this.qty --
            }
        },
        itemInWishlist (id) {
            if (this.userToken !='') {
                return this.$store.getters.wishlistItemById(id)
            }
        },
        addDelWishlistItem () {
            if (this.userToken != '') {
                if (this.itemInWishlist(this.good.id)) {
                    this.$store.dispatch('addDelWishlistItem', {good_id: this.good.id, authToken: this.userToken, action: 'del'})
                } else {
                    this.$store.dispatch('addDelWishlistItem', {good_id: this.good.id, authToken: this.userToken, action: 'add'})
                }
            }    
        },
        addToCart () {
            if (this.userToken != '') {
                this.loading = true
                setTimeout(() => {
                    this.$store.dispatch('addDelCartItem', 
                    {
                        good_id: this.good.id,
                        authToken: this.userToken,
                        quantity: this.qty,
                        action: 'add'
                    })
                    this.loading = false
                }, 50)    
            }
        },
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.loadGoodInfo(this.$route.params.good_id)
            },
        },
        good: {
            deep: true,
            handler() {
                if (this.good) {
                    this.setPageTitle(this.good.name)
                } else {
                    this.setPageTitle("ТД Сидроград. Страница не найдена!")
                }
            }
        },
    },
}
</script>